import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "col-12"
}
const _hoisted_2 = { class: "flex-row" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "flex-row" }
const _hoisted_5 = { class: "col-12" }
const _hoisted_6 = { class: "card-deck-wrapper" }
const _hoisted_7 = { class: "card-deck" }
const _hoisted_8 = {
  key: 0,
  class: "flex-row"
}
const _hoisted_9 = { class: "col-12" }
const _hoisted_10 = { class: "card-deck-wrapper" }
const _hoisted_11 = { class: "card-deck" }
const _hoisted_12 = { class: "flex-row" }
const _hoisted_13 = { class: "col-12" }
const _hoisted_14 = { class: "card-deck-wrapper" }
const _hoisted_15 = { class: "card-deck" }
const _hoisted_16 = {
  key: 1,
  class: "flex-row"
}
const _hoisted_17 = { class: "card-body" }
const _hoisted_18 = { class: "card-deck-wrapper" }
const _hoisted_19 = { class: "card-deck" }
const _hoisted_20 = {
  key: 1,
  class: "content-page"
}
const _hoisted_21 = {
  key: 0,
  class: "text-center"
}
const _hoisted_22 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MapBox = _resolveComponent("MapBox")!
  const _component_device_card = _resolveComponent("device-card")!
  const _component_device_proxy = _resolveComponent("device-proxy")!
  const _component_portal_status = _resolveComponent("portal-status")!
  const _component_log_summary = _resolveComponent("log-summary")!
  const _component_scroll_area = _resolveComponent("scroll-area")!

  return ($data.summaryReady)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_scroll_area, null, {
          default: _withCtx(() => [
            _createElementVNode("h2", null, _toDisplayString($data.summary.time), 1),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_MapBox, {
                  zoom: 2.5,
                  height: "480px",
                  locations: $data.locations
                }, null, 8, ["locations"])
              ])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      (_ctx.check_fleet('COMET-PRO'))
                        ? (_openBlock(), _createBlock(_component_device_card, {
                            key: 0,
                            title: "US DEVICES",
                            "button-label": "DEVICES",
                            fleet: "COMET-PRO",
                            "button-link": "COMET-PRO/devices",
                            onLocations: _cache[0] || (_cache[0] = (devices) => $data.locations = [...$data.locations, ...devices])
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.check_fleet('COMET-CA'))
                        ? (_openBlock(), _createBlock(_component_device_card, {
                            key: 1,
                            title: "CA DEVICES",
                            "button-label": "DEVICES",
                            fleet: "COMET-CA",
                            "button-link": "COMET-CA/devices",
                            onLocations: _cache[1] || (_cache[1] = (devices) => $data.locations = [...$data.locations, ...devices])
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.check_fleet('COMET-EU'))
                        ? (_openBlock(), _createBlock(_component_device_card, {
                            key: 2,
                            title: "EU DEVICES",
                            "button-label": "DEVICES",
                            fleet: "COMET-EU",
                            "button-link": "COMET-EU/devices",
                            onLocations: _cache[2] || (_cache[2] = (devices) => $data.locations = [...$data.locations, ...devices])
                          }))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ])
              ]),
              (_ctx.check_fleet('DEV'))
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("div", _hoisted_11, [
                          _createVNode(_component_device_card, {
                            title: "SLING DEVICES",
                            "button-label": "DEVICES",
                            fleet: "Auriga",
                            "button-link": "Auriga/devices",
                            onLocations: _cache[3] || (_cache[3] = (devices) => $data.locations = [...$data.locations, ...devices])
                          }),
                          _createVNode(_component_device_card, {
                            title: "COMET-DEV DEVICES",
                            "button-label": "DEVICES",
                            fleet: "COMET-DEV",
                            "button-link": "COMET-DEV/devices",
                            onLocations: _cache[4] || (_cache[4] = (devices) => $data.locations = [...$data.locations, ...devices])
                          })
                        ])
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("div", _hoisted_15, [
                      (_ctx.check_services('PROXY'))
                        ? (_openBlock(), _createBlock(_component_device_proxy, {
                            key: 0,
                            us: _ctx.check_fleet('COMET-PRO'),
                            prod: _ctx.check_fleet('COMET-PRO'),
                            eu: _ctx.check_fleet('COMET-EU'),
                            ca: _ctx.check_fleet('COMET-CA'),
                            val: _ctx.check_fleet('DEV')
                          }, null, 8, ["us", "prod", "eu", "ca", "val"]))
                        : _createCommentVNode("", true),
                      (_ctx.check_services('PORTAL'))
                        ? (_openBlock(), _createBlock(_component_portal_status, {
                            key: 1,
                            us: _ctx.check_fleet('COMET-PRO'),
                            prod: _ctx.check_fleet('COMET-PRO'),
                            eu: _ctx.check_fleet('COMET-EU'),
                            ca: _ctx.check_fleet('COMET-CA'),
                            val: _ctx.check_fleet('DEV')
                          }, null, 8, ["us", "prod", "eu", "ca", "val"]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ])
              ]),
              (_ctx.check_logging('CLOUD'))
                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "card-header" }, [
                      _createElementVNode("h3", { class: "text-success" }, "US SERVICES")
                    ], -1)),
                    _createElementVNode("div", _hoisted_17, [
                      _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("div", _hoisted_19, [
                          _createVNode(_component_log_summary, { service: "ariane" }),
                          _createVNode(_component_log_summary, { service: "crohn" }),
                          _createVNode(_component_log_summary, { service: "clipper" }),
                          _createVNode(_component_log_summary, { service: "auto-procedure" }),
                          _createVNode(_component_log_summary, { service: "pip" })
                        ])
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        })
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_20, [
        ($data.loadingSummary)
          ? (_openBlock(), _createElementBlock("div", _hoisted_21, _cache[6] || (_cache[6] = [
              _createElementVNode("h3", null, "Loading Summary...", -1),
              _createElementVNode("div", {
                class: "spinner-grow avatar-lg text-info",
                style: {"padding":"10%","margin":"10%"},
                role: "status"
              }, null, -1)
            ])))
          : _createCommentVNode("", true),
        ($data.error)
          ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
              _createElementVNode("h2", null, _toDisplayString($data.errorMessage) + "!", 1)
            ]))
          : _createCommentVNode("", true)
      ]))
}