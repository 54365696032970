import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "content-page" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "text-center"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "text-warning" }
const _hoisted_6 = {
  key: 2,
  class: "content"
}
const _hoisted_7 = { class: "text-info" }
const _hoisted_8 = { class: "report-container" }
const _hoisted_9 = { class: "w-100" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "text-warning col-auto" }
const _hoisted_12 = { class: "row report-row" }
const _hoisted_13 = { class: "col-1" }
const _hoisted_14 = { class: "col-2 text-nowrap text-truncate" }
const _hoisted_15 = { class: "col-3 text-nowrap text-truncate" }
const _hoisted_16 = { class: "col-4" }
const _hoisted_17 = { class: "col-2 d-flex-reverse align-items-center" }
const _hoisted_18 = ["href"]
const _hoisted_19 = { class: "box" }
const _hoisted_20 = ["src"]
const _hoisted_21 = ["href"]
const _hoisted_22 = { class: "box" }
const _hoisted_23 = ["src"]
const _hoisted_24 = { class: "row" }
const _hoisted_25 = { class: "text-info col-auto" }
const _hoisted_26 = { class: "row report-row" }
const _hoisted_27 = { class: "col-1" }
const _hoisted_28 = { class: "col-2 text-nowrap text-truncate" }
const _hoisted_29 = { class: "col-3 text-nowrap text-truncate" }
const _hoisted_30 = { class: "col-3" }
const _hoisted_31 = { class: "col-3 d-flex-reverse align-items-center" }
const _hoisted_32 = ["href"]
const _hoisted_33 = { class: "box" }
const _hoisted_34 = ["src"]
const _hoisted_35 = ["href"]
const _hoisted_36 = { class: "box" }
const _hoisted_37 = ["src"]
const _hoisted_38 = ["href"]
const _hoisted_39 = { class: "box" }
const _hoisted_40 = ["src"]
const _hoisted_41 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DeviceBadge = _resolveComponent("DeviceBadge")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.check_fleet(_ctx.fleet))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[2] || (_cache[2] = [
          _createElementVNode("h3", { class: "text-warning" }, "FLEET NOT AVAILABLE...", -1)
        ])))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("h3", null, _toDisplayString(_ctx.reportMessage), 1),
                _cache[3] || (_cache[3] = _createElementVNode("div", {
                  class: "spinner-grow avatar-lg text-info",
                  style: {"padding":"10%","margin":"10%"},
                  role: "status"
                }, null, -1))
              ]))
            : _createCommentVNode("", true),
          (_ctx.error)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("h2", _hoisted_5, "ERROR: " + _toDisplayString(_ctx.errorMessage), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.ready)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("h2", _hoisted_7, "DEVICE CERT REPORT FOR " + _toDisplayString(_ctx.fleet), 1),
                _createElementVNode("h3", null, _toDisplayString(_ctx.reportTime), 1),
                _createElementVNode("main", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    (_ctx.report.errors)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createElementVNode("div", _hoisted_10, [
                            _createElementVNode("button", {
                              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.csv_errors && _ctx.csv_errors(...args))),
                              class: "col-auto btn btn-outline-primary btn-rounded download"
                            }, _cache[4] || (_cache[4] = [
                              _createElementVNode("i", { class: "mdi mdi-download" }, null, -1)
                            ])),
                            _createElementVNode("h3", _hoisted_11, "ERRORS [" + _toDisplayString(_ctx.report.errors.length) + "]", 1)
                          ]),
                          _cache[5] || (_cache[5] = _createStaticVNode("<div class=\"row report-row\" data-v-e7f9b3c2><h3 class=\"col-1\" data-v-e7f9b3c2>STATUS</h3><h3 class=\"col-2\" data-v-e7f9b3c2>NAME</h3><h3 class=\"col-3\" data-v-e7f9b3c2>UUID</h3><h3 class=\"col-4\" data-v-e7f9b3c2>ERROR</h3><h3 class=\"col-2\" data-v-e7f9b3c2></h3></div>", 1)),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.report.errors, (error) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: error.device.uuid
                            }, [
                              _createElementVNode("div", _hoisted_12, [
                                _createElementVNode("div", _hoisted_13, [
                                  _createVNode(_component_DeviceBadge, {
                                    good: error.device.online,
                                    fail: !error.device.online,
                                    state: error.device.online?'ONLINE':'OFFLINE'
                                  }, null, 8, ["good", "fail", "state"])
                                ]),
                                _createElementVNode("div", _hoisted_14, _toDisplayString(error.device.name), 1),
                                _createElementVNode("div", _hoisted_15, _toDisplayString(error.device.uuid), 1),
                                _createElementVNode("div", _hoisted_16, _toDisplayString(error.error), 1),
                                _createElementVNode("div", _hoisted_17, [
                                  (_ctx.check_services('portal-admin'))
                                    ? (_openBlock(), _createElementBlock("a", {
                                        key: 0,
                                        class: "btn btn-info",
                                        target: "_blank",
                                        href: _ctx.get_portal_url(_ctx.fleet, error.device.uuid)
                                      }, [
                                        _createElementVNode("div", _hoisted_19, [
                                          _createElementVNode("img", {
                                            src: _ctx.get_image_url('/portal-196.png'),
                                            alt: "Virgo Admin Portal"
                                          }, null, 8, _hoisted_20)
                                        ])
                                      ], 8, _hoisted_18))
                                    : _createCommentVNode("", true),
                                  _createElementVNode("a", {
                                    class: "btn btn-info m-1",
                                    target: "_blank",
                                    href: _ctx.get_device_url(error.device.uuid)
                                  }, [
                                    _createElementVNode("div", _hoisted_22, [
                                      _createElementVNode("img", {
                                        src: _ctx.get_image_url('/virgo-dot.svg'),
                                        alt: "Virgo"
                                      }, null, 8, _hoisted_23)
                                    ])
                                  ], 8, _hoisted_21)
                                ])
                              ])
                            ]))
                          }), 128))
                        ], 64))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_24, [
                      _createElementVNode("button", {
                        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.csv_devices && _ctx.csv_devices(...args))),
                        class: "col-auto btn btn-outline-primary btn-rounded download"
                      }, _cache[6] || (_cache[6] = [
                        _createElementVNode("i", { class: "mdi mdi-download" }, null, -1)
                      ])),
                      _createElementVNode("h3", _hoisted_25, "DEVICES [" + _toDisplayString(_ctx.report.devices.length) + "]", 1)
                    ]),
                    _cache[7] || (_cache[7] = _createStaticVNode("<div class=\"row report-row\" data-v-e7f9b3c2><h3 class=\"col-1\" data-v-e7f9b3c2>STATUS</h3><h3 class=\"col-2\" data-v-e7f9b3c2>NAME</h3><h3 class=\"col-3\" data-v-e7f9b3c2>UUID</h3><h3 class=\"col-3\" data-v-e7f9b3c2>EXPIRES</h3><h3 class=\"col-3\" data-v-e7f9b3c2></h3></div>", 1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.devices, (device) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: device.device.uuid
                      }, [
                        _createElementVNode("div", _hoisted_26, [
                          _createElementVNode("div", _hoisted_27, [
                            _createVNode(_component_DeviceBadge, {
                              good: device.device.online,
                              fail: !device.device.online,
                              state: device.device.online?'ONLINE':'OFFLINE'
                            }, null, 8, ["good", "fail", "state"])
                          ]),
                          _createElementVNode("div", _hoisted_28, _toDisplayString(device.device.name), 1),
                          _createElementVNode("div", _hoisted_29, _toDisplayString(device.device.uuid), 1),
                          _createElementVNode("div", _hoisted_30, _toDisplayString(device.cert_info.not_valid_after), 1),
                          _createElementVNode("div", _hoisted_31, [
                            (_ctx.check_services('portal-admin'))
                              ? (_openBlock(), _createElementBlock("a", {
                                  key: 0,
                                  class: "btn btn-info",
                                  target: "_blank",
                                  href: _ctx.get_portal_url(_ctx.fleet, device.device.uuid)
                                }, [
                                  _createElementVNode("div", _hoisted_33, [
                                    _createElementVNode("img", {
                                      src: _ctx.get_image_url('/portal-196.png'),
                                      alt: "Virgo Admin Portal"
                                    }, null, 8, _hoisted_34)
                                  ])
                                ], 8, _hoisted_32))
                              : _createCommentVNode("", true),
                            (_ctx.check_services('balena'))
                              ? (_openBlock(), _createElementBlock("a", {
                                  key: 1,
                                  class: "btn btn-info m-1",
                                  target: "_blank",
                                  href: device.device.url
                                }, [
                                  _createElementVNode("div", _hoisted_36, [
                                    _createElementVNode("img", {
                                      src: _ctx.get_image_url('/balena_logo.png'),
                                      alt: "Balena.io"
                                    }, null, 8, _hoisted_37)
                                  ])
                                ], 8, _hoisted_35))
                              : _createCommentVNode("", true),
                            _createElementVNode("a", {
                              class: "btn btn-info m-1",
                              target: "_blank",
                              href: _ctx.get_device_url(device.device.uuid)
                            }, [
                              _createElementVNode("div", _hoisted_39, [
                                _createElementVNode("img", {
                                  src: _ctx.get_image_url('/virgo-dot.svg'),
                                  alt: "Virgo"
                                }, null, 8, _hoisted_40)
                              ])
                            ], 8, _hoisted_38),
                            (device.cert_info?.should_renew===true)
                              ? (_openBlock(), _createElementBlock("a", {
                                  key: 2,
                                  class: _normalizeClass({'m-1' : true, 'btn': true, 'btn-warning' : !device.renew_error, 'btn-danger' : device.renew_error, 'font-weight-bold' : true, }),
                                  onClick: ($event: any) => (_ctx.renew_device(device))
                                }, [
                                  _createElementVNode("div", {
                                    class: _normalizeClass({'spinner-grow': device.renewing, 'box': device.renewing} )
                                  }, [
                                    (!device.renewing)
                                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                          _createTextVNode(" RENEW ")
                                        ], 64))
                                      : _createCommentVNode("", true)
                                  ], 2)
                                ], 10, _hoisted_41))
                              : _createCommentVNode("", true)
                          ])
                        ])
                      ]))
                    }), 128))
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ], 64))
  ]))
}