import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "w-100" }
const _hoisted_2 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column, index) => {
        return (_openBlock(), _createElementBlock("p", {
          class: _normalizeClass(`${column.class} text-dark`),
          key: index
        }, _toDisplayString(column.name), 3))
      }), 128))
    ]),
    _createElementVNode("div", {
      class: "table-row",
      style: _normalizeStyle(_ctx.table_styles)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.row_columns, (row, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "row"
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.cells, (cell, cindex) => {
            return (_openBlock(), _createElementBlock("p", {
              key: cindex,
              class: _normalizeClass(cell.class)
            }, _toDisplayString(cell.value), 3))
          }), 128))
        ]))
      }), 128))
    ], 4)
  ]))
}