import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, Fragment as _Fragment, renderList as _renderList, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "d-flex flex-column justify-content-around" }
const _hoisted_3 = { class: "justify-content-between d-flex flex-row flex-wrap" }
const _hoisted_4 = { class: "p-0 d-flex justify-content-start" }
const _hoisted_5 = { class: "text-info align-items-center" }
const _hoisted_6 = { class: "p-0 d-flex justify-content-end" }
const _hoisted_7 = { class: "text-primary" }
const _hoisted_8 = { class: "col-12 links align-items-center d-flex flex-row flex-wrap justify-content-center" }
const _hoisted_9 = ["href"]
const _hoisted_10 = { class: "box" }
const _hoisted_11 = ["src"]
const _hoisted_12 = ["href"]
const _hoisted_13 = { class: "box" }
const _hoisted_14 = ["src"]
const _hoisted_15 = ["href"]
const _hoisted_16 = { class: "box" }
const _hoisted_17 = ["src"]
const _hoisted_18 = { class: "box" }
const _hoisted_19 = ["src"]
const _hoisted_20 = { class: "box" }
const _hoisted_21 = ["src"]
const _hoisted_22 = { class: "d-flex flex-row flex-wrap justify-content-center" }
const _hoisted_23 = {
  key: 0,
  class: "text-dark"
}
const _hoisted_24 = {
  key: 1,
  class: "text-warning"
}
const _hoisted_25 = { class: "col-12 align-self-center d-flex flex-row justify-content-center flex-wrap bg-light-lighten" }
const _hoisted_26 = { key: 0 }
const _hoisted_27 = { class: "card border" }
const _hoisted_28 = { class: "card-body w-100 card-deck" }
const _hoisted_29 = { class: "card border border-secondary card-margin" }
const _hoisted_30 = { class: "row align-self-center" }
const _hoisted_31 = { class: "card-footer" }
const _hoisted_32 = { class: "text-dark text-center" }
const _hoisted_33 = { class: "card border border-secondary card-margin" }
const _hoisted_34 = { class: "card border border-secondary card-margin" }
const _hoisted_35 = { class: "card border border-secondary card-margin" }
const _hoisted_36 = { class: "card border" }
const _hoisted_37 = { class: "card-body card-deck" }
const _hoisted_38 = { class: "card border border-secondary" }
const _hoisted_39 = { class: "card border border-secondary" }
const _hoisted_40 = { class: "card border border-secondary" }
const _hoisted_41 = { class: "card border border-secondary" }
const _hoisted_42 = { class: "card border border-secondary" }
const _hoisted_43 = { class: "card border border-secondary" }
const _hoisted_44 = {
  key: 1,
  class: "card border border-secondary"
}
const _hoisted_45 = { class: "card-body" }
const _hoisted_46 = { class: "d-flex flex-row flex-wrap" }
const _hoisted_47 = { class: "text-success" }
const _hoisted_48 = { class: "d-flex flex-row flex-wrap" }
const _hoisted_49 = { class: "text-success" }
const _hoisted_50 = { class: "d-flex flex-row flex-wrap" }
const _hoisted_51 = { class: "text-success" }
const _hoisted_52 = { class: "d-flex flex-row flex-wrap" }
const _hoisted_53 = { class: "text-success" }
const _hoisted_54 = { class: "d-flex flex-row flex-wrap" }
const _hoisted_55 = { class: "text-success" }
const _hoisted_56 = { class: "d-flex flex-row flex-wrap" }
const _hoisted_57 = { class: "text-success" }
const _hoisted_58 = { class: "d-flex flex-row flex-wrap" }
const _hoisted_59 = { class: "text-success" }
const _hoisted_60 = { class: "d-flex flex-row flex-wrap" }
const _hoisted_61 = { class: "text-success" }
const _hoisted_62 = { class: "d-flex flex-row flex-wrap" }
const _hoisted_63 = { class: "col-8 d-flex flex-row flex-wrap" }
const _hoisted_64 = { class: "d-flex flex-row flex-wrap" }
const _hoisted_65 = { class: "w-100" }
const _hoisted_66 = { class: "col-3 text-dark text-uppercase col-2" }
const _hoisted_67 = { class: "col-9 text-success col-auto" }
const _hoisted_68 = { class: "d-flex flex-row flex-wrap" }
const _hoisted_69 = { class: "card border border-secondary card-margin col-12" }
const _hoisted_70 = { class: "card-body" }
const _hoisted_71 = {
  key: 0,
  class: "text-warning"
}
const _hoisted_72 = {
  key: 0,
  class: "row"
}
const _hoisted_73 = { class: "card border border-secondary card-margin w-100" }
const _hoisted_74 = { class: "card-body" }
const _hoisted_75 = { class: "row" }
const _hoisted_76 = { class: "card border border-secondary card-margin col-12" }
const _hoisted_77 = { class: "card-body" }
const _hoisted_78 = {
  key: 1,
  class: "text-center"
}
const _hoisted_79 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_device_badge = _resolveComponent("device-badge")!
  const _component_DeviceConfiguration = _resolveComponent("DeviceConfiguration")!
  const _component_device_bandwidth_gauge = _resolveComponent("device-bandwidth-gauge")!
  const _component_gauge = _resolveComponent("gauge")!
  const _component_device_networking = _resolveComponent("device-networking")!
  const _component_device_cert_info = _resolveComponent("device-cert-info")!
  const _component_device_inference_graph = _resolveComponent("device-inference-graph")!
  const _component_log_graph = _resolveComponent("log-graph")!
  const _component_device_temp_graph = _resolveComponent("device-temp-graph")!
  const _component_device_pending_graph = _resolveComponent("device-pending-graph")!
  const _component_device_memory_graph = _resolveComponent("device-memory-graph")!
  const _component_device_cpu_graph = _resolveComponent("device-cpu-graph")!
  const _component_generic_table = _resolveComponent("generic-table")!
  const _component_ScrollArea = _resolveComponent("ScrollArea")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.ready)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("h4", _hoisted_5, _toDisplayString(_ctx.device.info.device_name), 1)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("h4", _hoisted_7, _toDisplayString(_ctx.device.time), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              (_ctx.fleet && _ctx.check_services('portal-admin'))
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    class: "btn btn-success",
                    target: "_blank",
                    href: _ctx.get_portal_url(_ctx.fleet, _ctx.device_uuid)
                  }, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("img", {
                        src: _ctx.get_image_url('/portal-196.png'),
                        alt: "Virgo Admin Portal"
                      }, null, 8, _hoisted_11)
                    ])
                  ], 8, _hoisted_9))
                : _createCommentVNode("", true),
              (_ctx.check_services('balena'))
                ? (_openBlock(), _createElementBlock("a", {
                    key: 1,
                    class: "btn btn-success",
                    target: "_blank",
                    href: _ctx.device.balena_url
                  }, [
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("img", {
                        src: _ctx.get_image_url('/balena_logo.png'),
                        alt: "Balena.io"
                      }, null, 8, _hoisted_14)
                    ])
                  ], 8, _hoisted_12))
                : _createCommentVNode("", true),
              (_ctx.check_services('datadog'))
                ? (_openBlock(), _createElementBlock("a", {
                    key: 2,
                    class: "btn btn-success",
                    target: "_blank",
                    href: _ctx.device.datadog_url
                  }, [
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("img", {
                        src: _ctx.get_image_url('/data_dog.jpeg'),
                        alt: "DataDog.com"
                      }, null, 8, _hoisted_17)
                    ])
                  ], 8, _hoisted_15))
                : _createCommentVNode("", true),
              _createElementVNode("a", {
                class: _normalizeClass({btn: true, 'btn-warning' : !_ctx.rebooting_error, 'btn-danger' : _ctx.rebooting_error}),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.reboot_device()))
              }, [
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("img", {
                    src: _ctx.get_image_url('/reboot.svg'),
                    alt: "Reboot Device",
                    class: _normalizeClass({'spinner-grow': _ctx.rebooting})
                  }, null, 10, _hoisted_19)
                ])
              ], 2),
              _createElementVNode("a", {
                class: _normalizeClass({btn: true, 'btn-warning' : !_ctx.restarting_error, 'btn-danger' : _ctx.restarting_error}),
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.restart_services()))
              }, [
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("img", {
                    src: _ctx.get_image_url('/restart.svg'),
                    alt: "Restart Services",
                    class: _normalizeClass({'spinner-grow': _ctx.restarting})
                  }, null, 10, _hoisted_21)
                ])
              ], 2),
              _createElementVNode("a", {
                class: _normalizeClass({btn: true, 'btn-info' : !_ctx.show_config, 'btn-light' : _ctx.show_config, 'h-100' : true }),
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.show_config=false))
              }, _cache[4] || (_cache[4] = [
                _createElementVNode("span", { class: "font-weight-bold" }, " DASHBOARD ", -1)
              ]), 2),
              _createElementVNode("a", {
                class: _normalizeClass({btn: true, 'btn-info' : _ctx.show_config, 'btn-light' : !_ctx.show_config, 'h-100' : true }),
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.show_config=true))
              }, _cache[5] || (_cache[5] = [
                _createElementVNode("div", { class: "font-weight-bold" }, " CONFIGURATION ", -1)
              ]), 2)
            ])
          ]),
          _createVNode(_component_ScrollArea, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_22, [
                (_ctx.device.info.is_online)
                  ? (_openBlock(), _createElementBlock("h4", _hoisted_23, _toDisplayString(_ctx.device_boot), 1))
                  : (_openBlock(), _createElementBlock("h4", _hoisted_24, "Last contact @" + _toDisplayString(_ctx.device.last_seen.substring(0,_ctx.device.last_seen.lastIndexOf('.'))), 1))
              ]),
              _createElementVNode("div", _hoisted_25, [
                _createVNode(_component_device_badge, {
                  good: _ctx.device.tags.includes('PERMANENT-POWER') || _ctx.device.tags.includes('ACTIVE')
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createElementVNode("h4", { class: "m-0" }, "FIXED POWER", -1)
                  ])),
                  _: 1
                }, 8, ["good"]),
                _createVNode(_component_device_badge, {
                  good: _ctx.device.info.is_online
                }, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createElementVNode("h4", { class: "m-0" }, "ONLINE", -1)
                  ])),
                  _: 1
                }, 8, ["good"]),
                _createVNode(_component_device_badge, {
                  good: _ctx.device.info.is_connected_to_vpn
                }, {
                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                    _createElementVNode("h4", { class: "m-0" }, "VPN", -1)
                  ])),
                  _: 1
                }, 8, ["good"]),
                (_ctx.device.device_status !== undefined)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createVNode(_component_device_badge, {
                        good: _ctx.device.device_status.streaming===true,
                        class: "device-badge device-badge-invert"
                      }, {
                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                          _createElementVNode("img", { src: "images/endo.svg" }, null, -1)
                        ])),
                        _: 1
                      }, 8, ["good"]),
                      (_ctx.is_disabled(_ctx.device.device_status.hdmi0))
                        ? (_openBlock(), _createBlock(_component_device_badge, {
                            key: 0,
                            good: _ctx.device.device_status.hdmi0==='online',
                            class: "device-badge"
                          }, {
                            default: _withCtx(() => _cache[10] || (_cache[10] = [
                              _createElementVNode("img", { src: "images/hdmi.svg" }, null, -1)
                            ])),
                            _: 1
                          }, 8, ["good"]))
                        : _createCommentVNode("", true),
                      (_ctx.is_disabled(_ctx.device.device_status.hdmi1))
                        ? (_openBlock(), _createBlock(_component_device_badge, {
                            key: 1,
                            good: _ctx.device.device_status.hdmi1==='online',
                            class: "device-badge"
                          }, {
                            default: _withCtx(() => _cache[11] || (_cache[11] = [
                              _createElementVNode("img", { src: "images/hdmi.svg" }, null, -1)
                            ])),
                            _: 1
                          }, 8, ["good"]))
                        : _createCommentVNode("", true),
                      (_ctx.is_disabled(_ctx.device.device_status.eth0))
                        ? (_openBlock(), _createBlock(_component_device_badge, {
                            key: 2,
                            good: _ctx.device.device_status.eth0==='online',
                            class: "device-badge"
                          }, {
                            default: _withCtx(() => _cache[12] || (_cache[12] = [
                              _createElementVNode("img", { src: "images/eth.svg" }, null, -1)
                            ])),
                            _: 1
                          }, 8, ["good"]))
                        : _createCommentVNode("", true),
                      (_ctx.is_disabled(_ctx.device.device_status.eth1))
                        ? (_openBlock(), _createBlock(_component_device_badge, {
                            key: 3,
                            good: _ctx.device.device_status.eth1==='online',
                            class: "device-badge"
                          }, {
                            default: _withCtx(() => _cache[13] || (_cache[13] = [
                              _createElementVNode("img", { src: "images/eth.svg" }, null, -1)
                            ])),
                            _: 1
                          }, 8, ["good"]))
                        : _createCommentVNode("", true),
                      (_ctx.is_disabled(_ctx.device.device_status.wifi))
                        ? (_openBlock(), _createBlock(_component_device_badge, {
                            key: 4,
                            good: _ctx.device.device_status.wifi==='online',
                            class: "device-badge"
                          }, {
                            default: _withCtx(() => _cache[14] || (_cache[14] = [
                              _createElementVNode("img", { src: "images/wifi.svg" }, null, -1)
                            ])),
                            _: 1
                          }, 8, ["good"]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_device_badge, {
                        good: _ctx.device.device_status.ariane!==false,
                        class: "device-badge"
                      }, {
                        default: _withCtx(() => _cache[15] || (_cache[15] = [
                          _createElementVNode("img", { src: "images/ariane.svg" }, null, -1)
                        ])),
                        _: 1
                      }, 8, ["good"]),
                      _createVNode(_component_device_badge, {
                        good: _ctx.device.device_status.vpn==='online' && _ctx.device.device_status.proxy!=='online',
                        warn: _ctx.device.device_status.vpn==='online' && _ctx.device.device_status.proxy==='online',
                        class: "device-badge device-badge-invert"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _cache[16] || (_cache[16] = _createElementVNode("img", { src: "images/vpn.svg" }, null, -1)),
                            (_ctx.device.device_status.proxy==='online')
                              ? (_openBlock(), _createElementBlock("p", _hoisted_26, " P "))
                              : _createCommentVNode("", true)
                          ])
                        ]),
                        _: 1
                      }, 8, ["good", "warn"])
                    ], 64))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.show_config)
                ? (_openBlock(), _createBlock(_component_DeviceConfiguration, { key: 0 }))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createElementVNode("div", _hoisted_27, [
                      _cache[21] || (_cache[21] = _createElementVNode("div", { class: "card-header" }, [
                        _createElementVNode("h4", { class: "text-dark text-center" }, "METRICS")
                      ], -1)),
                      _createElementVNode("div", _hoisted_28, [
                        (_ctx.device.device_status !== undefined)
                          ? (_openBlock(), _createBlock(_component_device_bandwidth_gauge, {
                              key: 0,
                              transport: _ctx.device.transport
                            }, null, 8, ["transport"]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_29, [
                          _cache[17] || (_cache[17] = _createElementVNode("div", { class: "card-header" }, [
                            _createElementVNode("h4", { class: "text-dark text-center" }, "TEMPERATURE")
                          ], -1)),
                          _createElementVNode("div", _hoisted_30, [
                            _createVNode(_component_gauge, {
                              label: _ctx.temperature_string,
                              color: _ctx.temperature_color,
                              reading: _ctx.temperature_percent,
                              type: "radialBar"
                            }, null, 8, ["label", "color", "reading"])
                          ]),
                          _createElementVNode("div", _hoisted_31, [
                            _createElementVNode("h5", _hoisted_32, _toDisplayString(_ctx.temperature_source), 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_33, [
                          _cache[18] || (_cache[18] = _createElementVNode("div", { class: "card-header" }, [
                            _createElementVNode("h4", { class: "text-dark text-center" }, "CPU")
                          ], -1)),
                          _createVNode(_component_gauge, {
                            label: _ctx.cpu_count,
                            color: _ctx.cpu_color,
                            reading: _ctx.cpu_percentage,
                            type: "radialBar",
                            class: "row justify-content-center"
                          }, null, 8, ["label", "color", "reading"])
                        ]),
                        _createElementVNode("div", _hoisted_34, [
                          _cache[19] || (_cache[19] = _createElementVNode("div", { class: "card-header" }, [
                            _createElementVNode("h4", { class: "text-dark text-center" }, "MEMORY")
                          ], -1)),
                          _createVNode(_component_gauge, {
                            label: _ctx.memory_size,
                            color: _ctx.memory_color,
                            reading: _ctx.memory_percentage,
                            type: "radialBar",
                            class: "row justify-content-center"
                          }, null, 8, ["label", "color", "reading"])
                        ]),
                        _createElementVNode("div", _hoisted_35, [
                          _cache[20] || (_cache[20] = _createElementVNode("div", { class: "card-header" }, [
                            _createElementVNode("h4", { class: "text-dark text-center" }, "STORAGE")
                          ], -1)),
                          _createVNode(_component_gauge, {
                            label: _ctx.storage_size,
                            color: _ctx.storage_color,
                            reading: _ctx.storage_percentage,
                            type: "radialBar",
                            class: "row justify-content-center"
                          }, null, 8, ["label", "color", "reading"])
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_36, [
                      _cache[22] || (_cache[22] = _createElementVNode("div", { class: "card-header" }, [
                        _createElementVNode("h4", { class: "text-dark text-center" }, "NETWORKING")
                      ], -1)),
                      _createElementVNode("div", _hoisted_37, [
                        (_ctx.device.network)
                          ? (_openBlock(), _createBlock(_component_device_networking, {
                              key: 0,
                              network: _ctx.device.network
                            }, null, 8, ["network"]))
                          : _createCommentVNode("", true),
                        _createVNode(_component_device_cert_info, {
                          cert_info: _ctx.device.cert_info
                        }, null, 8, ["cert_info"])
                      ])
                    ]),
                    (_ctx.device.device_status !== undefined)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createElementVNode("div", _hoisted_38, [
                            _cache[23] || (_cache[23] = _createElementVNode("div", { class: "card-header" }, [
                              _createElementVNode("h4", { class: "text-dark text-center" }, "INFERENCES")
                            ], -1)),
                            _createVNode(_component_device_inference_graph, {
                              height: "200px",
                              logs: _ctx.device.inference
                            }, null, 8, ["logs"])
                          ]),
                          _createElementVNode("div", _hoisted_39, [
                            _cache[24] || (_cache[24] = _createElementVNode("div", { class: "card-header" }, [
                              _createElementVNode("h4", { class: "text-dark text-center" }, "LOGS")
                            ], -1)),
                            _createVNode(_component_log_graph, {
                              height: "200px",
                              logs: _ctx.device.device_statuses,
                              id: "log-graphs",
                              host: _ctx.device.info.device_name
                            }, null, 8, ["logs", "host"])
                          ]),
                          _createElementVNode("div", _hoisted_40, [
                            _cache[25] || (_cache[25] = _createElementVNode("div", { class: "card-header" }, [
                              _createElementVNode("h4", { class: "text-dark text-center" }, "TEMPERATURES")
                            ], -1)),
                            _createVNode(_component_device_temp_graph, {
                              height: "200px",
                              logs: _ctx.device.device_statuses,
                              id: "temp-graphs"
                            }, null, 8, ["logs"])
                          ]),
                          _createElementVNode("div", _hoisted_41, [
                            _cache[26] || (_cache[26] = _createElementVNode("div", { class: "card-header" }, [
                              _createElementVNode("h4", { class: "text-dark text-center" }, "NETWORK")
                            ], -1)),
                            _createVNode(_component_device_pending_graph, {
                              height: "200px",
                              logs: _ctx.device.transport
                            }, null, 8, ["logs"])
                          ]),
                          _createElementVNode("div", _hoisted_42, [
                            _cache[27] || (_cache[27] = _createElementVNode("div", { class: "card-header" }, [
                              _createElementVNode("h4", { class: "text-dark text-center" }, "MEMORY")
                            ], -1)),
                            _createVNode(_component_device_memory_graph, {
                              height: "200px",
                              logs: _ctx.device.device_statuses
                            }, null, 8, ["logs"])
                          ]),
                          _createElementVNode("div", _hoisted_43, [
                            _cache[28] || (_cache[28] = _createElementVNode("div", { class: "card-header" }, [
                              _createElementVNode("h4", { class: "text-dark text-center" }, "CPU")
                            ], -1)),
                            _createVNode(_component_device_cpu_graph, {
                              height: "200px",
                              logs: _ctx.device.device_statuses
                            }, null, 8, ["logs"])
                          ])
                        ], 64))
                      : _createCommentVNode("", true),
                    (!_ctx.compactDisplay)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
                          _cache[42] || (_cache[42] = _createElementVNode("div", { class: "card-header" }, [
                            _createElementVNode("h3", { class: "text-dark" }, "BALENA")
                          ], -1)),
                          _createElementVNode("div", _hoisted_45, [
                            _createElementVNode("div", _hoisted_46, [
                              _cache[29] || (_cache[29] = _createElementVNode("h3", { class: "text-dark w-15" }, "UUID", -1)),
                              _createElementVNode("h3", _hoisted_47, _toDisplayString(_ctx.device.info.uuid), 1)
                            ]),
                            _createElementVNode("div", _hoisted_48, [
                              _cache[30] || (_cache[30] = _createElementVNode("h4", { class: "text-dark w-15" }, "DEVICE TYPE", -1)),
                              _createElementVNode("h4", _hoisted_49, _toDisplayString(_ctx.device.info.device_type), 1)
                            ]),
                            _createElementVNode("div", _hoisted_50, [
                              _cache[31] || (_cache[31] = _createElementVNode("h4", { class: "text-dark w-15" }, "OS", -1)),
                              _createElementVNode("h4", _hoisted_51, _toDisplayString(_ctx.device.info.os_version), 1)
                            ]),
                            _createElementVNode("div", _hoisted_52, [
                              _cache[32] || (_cache[32] = _createElementVNode("h4", { class: "text-dark w-15" }, "SUPERVISOR", -1)),
                              _createElementVNode("h4", _hoisted_53, _toDisplayString(_ctx.device.info.supervisor_version), 1)
                            ]),
                            _createElementVNode("div", _hoisted_54, [
                              _cache[33] || (_cache[33] = _createElementVNode("h4", { class: "text-dark w-15" }, "LOCATION", -1)),
                              _createElementVNode("h4", _hoisted_55, _toDisplayString(_ctx.device.info.location), 1)
                            ]),
                            _createElementVNode("div", _hoisted_56, [
                              _cache[34] || (_cache[34] = _createElementVNode("h4", { class: "text-dark w-15" }, "IPs", -1)),
                              _createElementVNode("h4", _hoisted_57, _toDisplayString(_ctx.device.info.ip_address), 1)
                            ]),
                            _createElementVNode("div", _hoisted_58, [
                              _cache[35] || (_cache[35] = _createElementVNode("h4", { class: "text-dark w-15" }, "MAC IDs", -1)),
                              _createElementVNode("h4", _hoisted_59, _toDisplayString(_ctx.device.info.mac_address), 1)
                            ]),
                            _createElementVNode("div", _hoisted_60, [
                              _cache[36] || (_cache[36] = _createElementVNode("h4", { class: "text-dark w-15" }, "NOTES", -1)),
                              _createElementVNode("h4", _hoisted_61, _toDisplayString(_ctx.device.info.note), 1)
                            ]),
                            _createElementVNode("div", _hoisted_62, [
                              _cache[37] || (_cache[37] = _createElementVNode("h4", { class: "text-dark w-15" }, "TAGS", -1)),
                              _createElementVNode("div", _hoisted_63, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.device.tags, (name) => {
                                  return (_openBlock(), _createBlock(_component_device_badge, {
                                    good: "",
                                    key: name,
                                    class: "device-badge"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(name), 1)
                                    ]),
                                    _: 2
                                  }, 1024))
                                }), 128))
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_64, [
                              _cache[38] || (_cache[38] = _createElementVNode("h4", { class: "text-dark w-15" }, "METRICS", -1)),
                              _createElementVNode("div", _hoisted_65, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.device.metrics, (value, key) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: key,
                                    class: "row"
                                  }, [
                                    _createElementVNode("h6", _hoisted_66, _toDisplayString(key), 1),
                                    _createElementVNode("h6", _hoisted_67, _toDisplayString(value), 1)
                                  ]))
                                }), 128))
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_68, [
                              _cache[39] || (_cache[39] = _createElementVNode("h4", { class: "text-dark col-2" }, "LOGS", -1)),
                              _createElementVNode("div", _hoisted_69, [
                                _createElementVNode("div", _hoisted_70, [
                                  (_ctx.device.logsDatadog == true)
                                    ? (_openBlock(), _createElementBlock("h3", _hoisted_71, "NO LOGS"))
                                    : (_openBlock(), _createBlock(_component_generic_table, {
                                        key: 1,
                                        maxHeight: "300px",
                                        columns: [
                                                {
                                                    name:'TIME',
                                                    class:'col-3 text-nowrap',
                                                    member: 'timestamp',
                                                    format: (value) => { if (typeof value === 'object') return 'NA'; return new Date(value).toISOString(); }
                                                },
                                                {
                                                    name:'MESSAGE',
                                                    class:'col-9',
                                                    member: 'message'
                                                }],
                                        items: _ctx.device.logs
                                      }, null, 8, ["columns", "items"]))
                                ])
                              ])
                            ])
                          ]),
                          (_ctx.device.logsDatadog)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_72, [
                                _createElementVNode("div", _hoisted_73, [
                                  _cache[41] || (_cache[41] = _createElementVNode("div", { class: "card-header" }, [
                                    _createElementVNode("h3", { class: "text-dark" }, "DATADOG")
                                  ], -1)),
                                  _createElementVNode("div", _hoisted_74, [
                                    _createElementVNode("div", _hoisted_75, [
                                      _cache[40] || (_cache[40] = _createElementVNode("h4", { class: "text-dark col-2" }, "LOGS", -1)),
                                      _createElementVNode("div", _hoisted_76, [
                                        _createElementVNode("div", _hoisted_77, [
                                          _createVNode(_component_generic_table, {
                                            maxHeight: "300px",
                                            columns: [
                                                        {
                                                            name:'TIME',
                                                            class:'col-3 text-nowrap',
                                                            member: 'timestamp',
                                                            format: (value) => { if (typeof value === 'object') return 'NA'; return new Date(value).toISOString(); }
                                                        },
                                                        {
                                                            name:'MESSAGE',
                                                            class:'col-9',
                                                            member: 'message'
                                                        }],
                                            items: _ctx.device.logs
                                          }, null, 8, ["columns", "items"])
                                        ])
                                      ])
                                    ])
                                  ])
                                ])
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true)
                  ], 64))
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_78, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.message), 1),
          _cache[43] || (_cache[43] = _createElementVNode("div", {
            class: "spinner-grow avatar-lg text-info",
            style: {"padding":"10%","margin":"10%"},
            role: "status"
          }, null, -1))
        ]))
      : _createCommentVNode("", true),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_79, [
          _createElementVNode("h2", null, _toDisplayString(_ctx.errorMessage) + "!", 1)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}