import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "card border border-secondary" }
const _hoisted_2 = { class: "card-body w-100 text-success" }
const _hoisted_3 = { class: "col-2 text-info" }
const _hoisted_4 = { class: "col-1 text-info" }
const _hoisted_5 = { class: "col-2 text-info" }
const _hoisted_6 = { class: "col-2 text-info" }
const _hoisted_7 = { class: "col-2 text-info" }
const _hoisted_8 = { class: "card-footer text-center" }
const _hoisted_9 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "card-header" }, [
      _createElementVNode("h4", { class: "text-dark text-center" }, "NETWORK CONFIGURATIONS")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"row\"><div class=\"col-2\">NAME</div><div class=\"col-1\">TYPE</div><div class=\"col-2\">SSID</div><div class=\"col-2\">IP</div><div class=\"col-2\">DNS</div></div>", 1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.configs, (config) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "row",
          key: config.id
        }, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(config.id), 1),
          _createElementVNode("div", _hoisted_4, _toDisplayString(config.type), 1),
          _createElementVNode("div", _hoisted_5, _toDisplayString(config.ssid??"--"), 1),
          _createElementVNode("div", _hoisted_6, _toDisplayString(config.address1??"--"), 1),
          _createElementVNode("div", _hoisted_7, _toDisplayString(config.dns??"--"), 1)
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("a", {
          class: _normalizeClass({'col-12' : true, btn: true, 'btn-warning' : !_ctx.update_error, 'btn-danger' : _ctx.update_error, 'font-weight-bold' : true, }),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.update_connections()))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass({'spinner-grow': _ctx.updating})
          }, [
            (!_ctx.updating)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(" CHECK ")
                ], 64))
              : _createCommentVNode("", true)
          ], 2)
        ], 2)
      ])
    ])
  ]))
}