import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "content-page" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "container-xl"
}
const _hoisted_4 = {
  key: 1,
  class: "text-center"
}
const _hoisted_5 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DeviceTable = _resolveComponent("DeviceTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.check_fleet(_ctx.fleet))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[0] || (_cache[0] = [
          _createElementVNode("h3", { class: "text-warning" }, "FLEET NOT AVAILABLE...", -1)
        ])))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.devicesReady)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_DeviceTable, {
                  devices: _ctx.devices,
                  title: `${_ctx.fleet.toUpperCase()} DEVICES`,
                  time: _ctx.reportTime,
                  fleet: _ctx.fleet,
                  canProvision: _ctx.fleet === 'comet-pool'
                }, null, 8, ["devices", "title", "time", "fleet", "canProvision"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.loadingDevices)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[1] || (_cache[1] = [
                _createElementVNode("h3", null, "Loading devices...", -1),
                _createElementVNode("div", {
                  class: "spinner-grow avatar-lg text-info",
                  style: {"padding":"10%","margin":"10%"},
                  role: "status"
                }, null, -1)
              ])))
            : _createCommentVNode("", true),
          (_ctx.deviceError)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("h2", null, _toDisplayString(_ctx.errorMessage) + "!", 1)
              ]))
            : _createCommentVNode("", true)
        ], 64))
  ]))
}