import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "card border border-secondary m-1" }
const _hoisted_2 = { class: "card-body col-12" }
const _hoisted_3 = {
  key: 0,
  class: "row"
}
const _hoisted_4 = { class: "col-1" }
const _hoisted_5 = { class: "col-9 text-info" }
const _hoisted_6 = { class: "col-9 text-info" }
const _hoisted_7 = { class: "card-footer text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "card-header" }, [
      _createElementVNode("h4", { class: "text-success text-center" }, "PORTAL")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.ready)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[0] || (_cache[0] = [
            _createElementVNode("div", {
              class: "spinner-grow avatar-lg text-info col-4 loading-spinner",
              role: "status"
            }, null, -1)
          ])))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.portals, (portal) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "row",
              key: portal.name
            }, [
              _createElementVNode("h4", _hoisted_4, _toDisplayString(portal.name), 1),
              (portal?.last_response?.status == 200)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createElementVNode("h4", {
                      class: "col-2 text-center",
                      style: _normalizeStyle(_ctx.get_status_style(portal))
                    }, _toDisplayString(_ctx.get_status_name(portal)), 5),
                    _createElementVNode("h4", _hoisted_5, _toDisplayString(portal?.last_response?.content?.options?.connect_url), 1)
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createElementVNode("h4", {
                      class: "col-2 text-center",
                      style: _normalizeStyle(_ctx.get_status_style(portal))
                    }, _toDisplayString(portal?.status), 5),
                    _createElementVNode("h4", _hoisted_6, _toDisplayString(portal?.last_response?.error??"UNKNOWN"), 1)
                  ], 64))
            ]))
          }), 128))
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("h4", {
        style: _normalizeStyle(_ctx.get_style)
      }, _toDisplayString(_ctx.get_status), 5)
    ])
  ]))
}