import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "card border border-secondary m-1 card-margin" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "text-success text-center text-uppercase" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = {
  key: 0,
  class: "row"
}
const _hoisted_6 = {
  key: 0,
  class: "text-warning"
}
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "text-uppercase col-7" }
const _hoisted_9 = { class: "col-5 text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.service), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (!_ctx.ready)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[0] || (_cache[0] = [
            _createElementVNode("div", {
              class: "spinner-grow avatar-lg text-info col-4 loading-spinner",
              role: "status"
            }, null, -1)
          ])))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.error)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.error), 1))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.logs, (value, key) => {
              return (_openBlock(), _createElementBlock("div", {
                key: key,
                class: "row text-info"
              }, [
                _createElementVNode("a", {
                  href: value.link,
                  target: "_blank",
                  class: "log-link row w-100"
                }, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("p", null, _toDisplayString(key), 1)
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("p", null, _toDisplayString(value.count), 1)
                  ])
                ], 8, _hoisted_7)
              ]))
            }), 128))
          ], 64))
    ])
  ]))
}