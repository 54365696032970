import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-center"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 2,
  class: "col-10 m-auto p-1"
}
const _hoisted_4 = { class: "card border border-secondary" }
const _hoisted_5 = { class: "card-body w-100 text-success" }
const _hoisted_6 = {
  key: 0,
  class: "row h-100 align-items-center"
}
const _hoisted_7 = { class: "dropdown col-7 text-md-right" }
const _hoisted_8 = {
  key: 1,
  class: "row h-100 align-items-center"
}
const _hoisted_9 = {
  key: 0,
  class: "dropdown col-7 text-md-right"
}
const _hoisted_10 = {
  key: 1,
  class: "dropdown col-7 text-md-right"
}
const _hoisted_11 = { class: "col-4 h-100" }
const _hoisted_12 = { class: "col-3 h-100" }
const _hoisted_13 = { class: "col-3 h-100" }
const _hoisted_14 = { class: "col-4 h-100" }
const _hoisted_15 = { class: "col-1 h-100" }
const _hoisted_16 = { class: "col-3 h-100" }
const _hoisted_17 = { class: "col-2 h-100" }
const _hoisted_18 = { class: "card-footer text-center" }
const _hoisted_19 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DeviceConnections = _resolveComponent("DeviceConnections")!
  const _component_DeviceNetworkConfig = _resolveComponent("DeviceNetworkConfig")!
  const _component_DeviceCertUploader = _resolveComponent("DeviceCertUploader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[1] || (_cache[1] = [
          _createElementVNode("div", {
            class: "spinner-grow avatar-lg text-info",
            style: {"padding":"10%","margin":"10%"},
            role: "status"
          }, null, -1)
        ])))
      : _createCommentVNode("", true),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("h2", null, _toDisplayString(_ctx.errorMessage) + "!", 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.ready)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_DeviceConnections, { device_uuid: _ctx.device_uuid }, null, 8, ["device_uuid"]),
          _createVNode(_component_DeviceNetworkConfig, {
            ethernet: "",
            device_uuid: _ctx.device_uuid
          }, null, 8, ["device_uuid"]),
          _createVNode(_component_DeviceNetworkConfig, { device_uuid: _ctx.device_uuid }, null, 8, ["device_uuid"]),
          _createVNode(_component_DeviceCertUploader),
          _createElementVNode("div", _hoisted_4, [
            _cache[6] || (_cache[6] = _createElementVNode("div", { class: "card-header" }, [
              _createElementVNode("h4", { class: "text-dark text-center" }, "DEVICE INFORMATION")
            ], -1)),
            _createElementVNode("div", _hoisted_5, [
              (_ctx.query.model)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _cache[2] || (_cache[2] = _createElementVNode("h3", { class: "col-5 text-dark h-100" }, "CONFIGURATION", -1)),
                    _createElementVNode("h3", _hoisted_7, _toDisplayString(_ctx.query.model), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.capture)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _cache[3] || (_cache[3] = _createElementVNode("h3", { class: "col-5 text-dark h-100" }, "CAPTURE", -1)),
                    (_ctx.capture)
                      ? (_openBlock(), _createElementBlock("h3", _hoisted_9, " DETECTED "))
                      : _createCommentVNode("", true),
                    (!_ctx.capture)
                      ? (_openBlock(), _createElementBlock("h3", _hoisted_10, " NOT FOUND "))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.networks.length > 0)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                    _cache[4] || (_cache[4] = _createStaticVNode("<h3 class=\"col-5 text-info h-100 text-info\" data-v-120393fa>NETWORKS</h3><div class=\"row h-100 align-items-center\" data-v-120393fa><h4 class=\"col-4 h-100\" data-v-120393fa>NAME</h4><h4 class=\"col-3 h-100\" data-v-120393fa>TYPE</h4><h4 class=\"col-3 h-100\" data-v-120393fa>DEVICE</h4></div>", 2)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.networks, (network) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "row h-100 align-items-center text-dark",
                        key: network.id
                      }, [
                        _createElementVNode("h4", _hoisted_11, _toDisplayString(network.name), 1),
                        _createElementVNode("h4", _hoisted_12, _toDisplayString(network.type), 1),
                        _createElementVNode("h4", _hoisted_13, _toDisplayString(network.device), 1)
                      ]))
                    }), 128))
                  ], 64))
                : _createCommentVNode("", true),
              (_ctx.readings.length > 0)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                    _cache[5] || (_cache[5] = _createStaticVNode("<h3 class=\"col-5 text-info h-100\" data-v-120393fa>WIFI</h3><div class=\"row h-100 align-items-center\" data-v-120393fa><h4 class=\"col-4 h-100\" data-v-120393fa>SSID</h4><h4 class=\"col-1 h-100\" data-v-120393fa>SS</h4><h4 class=\"col-3 h-100\" data-v-120393fa>SPEED</h4><h4 class=\"col-2 h-100\" data-v-120393fa>TYPE</h4></div>", 2)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.readings, (wifi) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: _normalizeClass({ 'row' : true, 'h-100' : true, 'align-items-center' : true, 'text-dark' : !wifi.InUse, 'bg-light' : wifi.InUse }),
                        key: wifi.id
                      }, [
                        _createElementVNode("h4", _hoisted_14, _toDisplayString(wifi.SSID), 1),
                        _createElementVNode("h4", _hoisted_15, _toDisplayString(wifi.strength), 1),
                        _createElementVNode("h4", _hoisted_16, _toDisplayString(wifi.speed) + " " + _toDisplayString(wifi.measure), 1),
                        _createElementVNode("h4", _hoisted_17, _toDisplayString(wifi.type) + "/" + _toDisplayString(wifi.encrypt), 1)
                      ], 2))
                    }), 128))
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("a", {
                  class: _normalizeClass({'col-12' : true, btn: true, 'btn-warning' : !_ctx.query_error, 'btn-danger' : _ctx.query_error, 'font-weight-bold' : true, }),
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.query_device()))
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass({'spinner-grow': _ctx.querying})
                  }, [
                    (!_ctx.querying)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createTextVNode(" QUERY DEVICE ")
                        ], 64))
                      : _createCommentVNode("", true)
                  ], 2)
                ], 2)
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}