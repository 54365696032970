import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "card border border-secondary" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "row align-items-center" }
const _hoisted_4 = { class: "col-6" }
const _hoisted_5 = { class: "text-right" }
const _hoisted_6 = { class: "card-body" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-4" }
const _hoisted_9 = { class: "form-row" }
const _hoisted_10 = { class: "col-auto" }
const _hoisted_11 = { class: "form-group mb-0" }
const _hoisted_12 = {
  key: 0,
  class: "text-muted"
}
const _hoisted_13 = { class: "col-6 ml-auto" }
const _hoisted_14 = { class: "form-row justify-content-end align-items-center" }
const _hoisted_15 = { class: "col-auto" }
const _hoisted_16 = { class: "dropdown" }
const _hoisted_17 = ["disabled"]
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { key: 1 }
const _hoisted_20 = { class: "dropdown-menu dropdown-menu-scrollable" }
const _hoisted_21 = ["onClick"]
const _hoisted_22 = { class: "col-auto" }
const _hoisted_23 = { class: "form-group mb-0" }
const _hoisted_24 = { class: "col-auto" }
const _hoisted_25 = { class: "text-right" }
const _hoisted_26 = ["disabled"]
const _hoisted_27 = { key: 0 }
const _hoisted_28 = { key: 1 }
const _hoisted_29 = {
  key: 0,
  class: "form-row justify-content-end"
}
const _hoisted_30 = { class: "col" }
const _hoisted_31 = { class: "wifi-configuration-settings mt-2 p-2" }
const _hoisted_32 = { class: "form-group row" }
const _hoisted_33 = {
  for: "wifi-password",
  class: "col-form-label col-4 text-right"
}
const _hoisted_34 = { class: "col-8" }
const _hoisted_35 = ["name", "onUpdate:modelValue", "placeholder"]
const _hoisted_36 = { class: "form-group row" }
const _hoisted_37 = { class: "col-8" }
const _hoisted_38 = ["name", "placeholder"]
const _hoisted_39 = {
  key: 1,
  class: "form-group row"
}
const _hoisted_40 = ["for"]
const _hoisted_41 = { class: "col-8" }
const _hoisted_42 = ["id", "onUpdate:modelValue", "placeholder"]
const _hoisted_43 = {
  key: 0,
  class: "alert alert-danger mt-3 mb-0",
  role: "alert"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "col-6" }, [
          _createElementVNode("h5", { class: "m-0" }, "Device provisioning")
        ], -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, " Selected devices: " + _toDisplayString(_ctx.selectedDevices.length), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _cache[5] || (_cache[5] = _createElementVNode("label", {
                  for: "provisioning-device-number",
                  class: "sr-only"
                }, "Provision a number of devices", -1)),
                _withDirectives(_createElementVNode("input", {
                  style: {"min-width":"220px"},
                  type: "number",
                  class: "form-control",
                  id: "provisioning-device-number",
                  placeholder: "Provision the first X devices",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.deviceNumber) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.deviceNumber]
                ]),
                (_ctx.deviceNumber > 0)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_12, " This field will override any device selection. "))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("button", {
                  type: "button",
                  "data-toggle": "dropdown",
                  class: "btn btn-secondary dropdown-toggle",
                  style: {"min-width":"190px"},
                  disabled: _ctx.loadingNetConfigs
                }, [
                  (_ctx.netConfig)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_ctx.netConfig.name), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_19, "WiFi Configuration"))
                ], 8, _hoisted_17),
                _createElementVNode("div", _hoisted_20, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.netConfigs, (config) => {
                    return (_openBlock(), _createElementBlock("a", {
                      href: "#",
                      class: "dropdown-item",
                      key: config.name,
                      onClick: (event) => {
                    event.preventDefault();
                    _ctx.setNetConfig(config);
                  }
                    }, _toDisplayString(config.name), 9, _hoisted_21))
                  }), 128))
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("div", _hoisted_23, [
                _cache[6] || (_cache[6] = _createElementVNode("label", {
                  for: "provisioning-tags",
                  class: "sr-only"
                }, "Tags", -1)),
                _withDirectives(_createElementVNode("input", {
                  style: {"min-width":"240px"},
                  type: "text",
                  class: "form-control",
                  id: "provisioning-tags",
                  placeholder: "Comma separated list of tags",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tags) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.tags]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("div", _hoisted_25, [
                _createElementVNode("button", {
                  class: "btn btn-success",
                  disabled: (!_ctx.deviceNumber && !_ctx.selectedDevices.length) || _ctx.submittingProvisioning || _ctx.fields['PASSWORD'] !== _ctx.passwordConfirm,
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onProvision && _ctx.onProvision(...args)))
                }, [
                  (_ctx.submittingProvisioning)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_27, "Provisioning..."))
                    : (_openBlock(), _createElementBlock("span", _hoisted_28, "Provision devices"))
                ], 8, _hoisted_26)
              ])
            ])
          ]),
          (_ctx.netConfig)
            ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                _createElementVNode("div", _hoisted_30, [
                  _createElementVNode("div", _hoisted_31, [
                    _cache[7] || (_cache[7] = _createElementVNode("h5", null, "WiFi configuration:", -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.netConfig.fields, (field) => {
                      return (_openBlock(), _createElementBlock(_Fragment, { key: field }, [
                        (field.includes('PASSWORD'))
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              _createElementVNode("div", _hoisted_32, [
                                _createElementVNode("label", _hoisted_33, _toDisplayString(field), 1),
                                _createElementVNode("div", _hoisted_34, [
                                  _withDirectives(_createElementVNode("input", {
                                    id: "wifi-password",
                                    type: "password",
                                    class: "form-control",
                                    name: field,
                                    "onUpdate:modelValue": ($event: any) => ((_ctx.fields[field]) = $event),
                                    placeholder: field
                                  }, null, 8, _hoisted_35), [
                                    [_vModelText, _ctx.fields[field]]
                                  ])
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_36, [
                                _createElementVNode("label", {
                                  for: "wifi-password-confirm",
                                  class: _normalizeClass({'col-form-label col-4 text-right': true, 'text-danger': _ctx.fields[field] !== _ctx.passwordConfirm})
                                }, " Confirm " + _toDisplayString(field), 3),
                                _createElementVNode("div", _hoisted_37, [
                                  _withDirectives(_createElementVNode("input", {
                                    id: "wifi-password-confirm",
                                    type: "password",
                                    class: "form-control",
                                    name: `${field}-CONFIRM`,
                                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.passwordConfirm) = $event)),
                                    placeholder: `Confirm ${field}`
                                  }, null, 8, _hoisted_38), [
                                    [_vModelText, _ctx.passwordConfirm]
                                  ])
                                ])
                              ])
                            ], 64))
                          : (_openBlock(), _createElementBlock("div", _hoisted_39, [
                              _createElementVNode("label", {
                                for: field,
                                class: "col-form-label col-4 text-right"
                              }, _toDisplayString(field), 9, _hoisted_40),
                              _createElementVNode("div", _hoisted_41, [
                                _withDirectives(_createElementVNode("input", {
                                  id: field,
                                  type: "text",
                                  class: "form-control",
                                  name: "field",
                                  "onUpdate:modelValue": ($event: any) => ((_ctx.fields[field]) = $event),
                                  placeholder: field
                                }, null, 8, _hoisted_42), [
                                  [_vModelText, _ctx.fields[field]]
                                ])
                              ])
                            ]))
                      ], 64))
                    }), 128))
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      (_ctx.error)
        ? (_openBlock(), _createElementBlock("div", _hoisted_43, _toDisplayString(_ctx.error), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}