import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "card border border-secondary card-margin" }
const _hoisted_2 = { class: "card-body device-row" }
const _hoisted_3 = { class: "col-3" }
const _hoisted_4 = { class: "col-9" }
const _hoisted_5 = { class: "card-header" }
const _hoisted_6 = { class: "text-dark text-center" }
const _hoisted_7 = { class: "card-body device-row" }
const _hoisted_8 = { class: "col-3" }
const _hoisted_9 = {
  key: 0,
  class: "col-9"
}
const _hoisted_10 = {
  key: 1,
  class: "col-9"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "card-header" }, [
        _createElementVNode("h4", { class: "text-dark text-center" }, "DNS")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dns, (item, key, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "row",
            key: index
          }, [
            _createElementVNode("p", _hoisted_3, _toDisplayString(key), 1),
            _createElementVNode("p", _hoisted_4, _toDisplayString(item), 1)
          ]))
        }), 128))
      ])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.devices, (item, key, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "card border border-secondary card-margin",
        key: index
      }, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("h4", _hoisted_6, _toDisplayString(key), 1)
        ]),
        _createElementVNode("div", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item, (item, key, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "row w-100",
              key: index
            }, [
              _createElementVNode("p", _hoisted_8, _toDisplayString(key), 1),
              ((typeof item === 'string'))
                ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(item), 1))
                : (_openBlock(), _createElementBlock("p", _hoisted_10, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item, (obj, index) => {
                      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                        _createTextVNode(_toDisplayString(obj) + " ", 1),
                        _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1))
                      ], 64))
                    }), 128))
                  ]))
            ]))
          }), 128))
        ])
      ]))
    }), 128))
  ], 64))
}