import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row"
}
const _hoisted_2 = {
  key: 0,
  class: "text-warning"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexcharts = _resolveComponent("apexcharts")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.ready)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
          _createElementVNode("div", {
            class: "spinner-grow avatar-lg text-info col-4 loading-spinner",
            role: "status"
          }, null, -1)
        ])))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.error)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.error), 1))
            : (_openBlock(), _createBlock(_component_apexcharts, {
                key: 1,
                class: _normalizeClass(_ctx.get_classes),
                width: _ctx.width,
                height: _ctx.height,
                type: "line",
                series: _ctx.series,
                options: _ctx.options
              }, null, 8, ["class", "width", "height", "series", "options"]))
        ], 64))
  ]))
}