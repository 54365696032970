import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "topnav-navbar topnav-navbar-dark navbar-nav" }
const _hoisted_2 = { class: "align-content-center align-content-between d-flex flex-row justify-content-around" }
const _hoisted_3 = { class: "d-flex justify-content-start col-auto p-2" }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "text-info m-1" }
const _hoisted_7 = { class: "dropdown-menu dropdown-lg p-0 font-16" }
const _hoisted_8 = { class: "scrollable-menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("a", {
          href: _ctx.get_base_url()
        }, [
          _createElementVNode("img", {
            src: _ctx.get_image_url('/virgo.svg'),
            alt: "Virgo",
            class: "virgo-logo"
          }, null, 8, _hoisted_5)
        ], 8, _hoisted_4),
        _createElementVNode("h6", _hoisted_6, _toDisplayString(_ctx.get_build()), 1)
      ]),
      _cache[15] || (_cache[15] = _createElementVNode("div", { class: "d-flex w-100" }, null, -1)),
      _cache[16] || (_cache[16] = _createElementVNode("a", {
        class: "dropdown-toggle arrow-none d-flex justify-content-end p-1",
        "data-toggle": "dropdown",
        href: "#",
        role: "button",
        "aria-haspopup": "false",
        "aria-expanded": "false"
      }, [
        _createElementVNode("i", { class: "dripicons-view-apps top-menu-icon text-dark" })
      ], -1)),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_router_link, {
            to: "/",
            class: "dropdown-item notify-item"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode(" Home ")
            ])),
            _: 1
          }),
          (_ctx.check_fleet('COMET-PRO'))
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: "/COMET-PRO/devices",
                class: "dropdown-item notify-item"
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode(" US Devices ")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.check_fleet('COMET-EU'))
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 1,
                to: "/COMET-EU/devices",
                class: "dropdown-item notify-item"
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode(" EU Devices ")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.check_fleet('COMET-CA'))
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 2,
                to: "/COMET-CA/devices",
                class: "dropdown-item notify-item"
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode(" CA Devices ")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.check_fleet('comet-pool'))
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 3,
                to: "/comet-pool/devices",
                class: "dropdown-item notify-item"
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode(" Comet-POOL Devices ")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.check_fleet('DEV'))
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                _createVNode(_component_router_link, {
                  to: "/SLING-DEV/devices",
                  class: "dropdown-item notify-item"
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode(" Sling-DEV Devices ")
                  ])),
                  _: 1
                }),
                _createVNode(_component_router_link, {
                  to: "/Auriga/devices",
                  class: "dropdown-item notify-item"
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode(" Sling Devices ")
                  ])),
                  _: 1
                }),
                _createVNode(_component_router_link, {
                  to: "/COMET-DEV/devices",
                  class: "dropdown-item notify-item"
                }, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createTextVNode(" Comet-DEV Devices ")
                  ])),
                  _: 1
                })
              ], 64))
            : _createCommentVNode("", true),
          (_ctx.check_reports('DEVICES'))
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                (_ctx.check_fleet('COMET-PRO'))
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      to: "/reports/balena/comet/COMET-PRO",
                      class: "dropdown-item notify-item"
                    }, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createTextVNode(" US Comet Report ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.check_fleet('COMET-EU'))
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 1,
                      to: "/reports/balena/comet/COMET-EU",
                      class: "dropdown-item notify-item"
                    }, {
                      default: _withCtx(() => _cache[9] || (_cache[9] = [
                        _createTextVNode(" EU Comet Report ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.check_fleet('COMET-CA'))
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 2,
                      to: "/reports/balena/comet/COMET-CA",
                      class: "dropdown-item notify-item"
                    }, {
                      default: _withCtx(() => _cache[10] || (_cache[10] = [
                        _createTextVNode(" CA Comet Report ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true),
          (_ctx.check_reports('CERTS'))
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 6 }, [
                (_ctx.check_fleet('COMET-PRO'))
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      to: "/reports/device/certs/COMET-PRO",
                      class: "dropdown-item notify-item"
                    }, {
                      default: _withCtx(() => _cache[11] || (_cache[11] = [
                        _createTextVNode(" US Device Certs Report ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.check_fleet('COMET-EU'))
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 1,
                      to: "/reports/device/certs/COMET-EU",
                      class: "dropdown-item notify-item"
                    }, {
                      default: _withCtx(() => _cache[12] || (_cache[12] = [
                        _createTextVNode(" EU Device Certs Report ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.check_fleet('COMET-CA'))
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 2,
                      to: "/reports/device/certs/COMET-CA",
                      class: "dropdown-item notify-item"
                    }, {
                      default: _withCtx(() => _cache[13] || (_cache[13] = [
                        _createTextVNode(" CA Device Certs Report ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.check_fleet('COMET-DEV'))
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 3,
                      to: "/reports/device/certs/COMET-DEV",
                      class: "dropdown-item notify-item"
                    }, {
                      default: _withCtx(() => _cache[14] || (_cache[14] = [
                        _createTextVNode(" DEV Device Certs Report ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}