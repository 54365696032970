import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "vh-100 d-flex align-items-center" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row justify-content-center" }
const _hoisted_4 = { class: "col-md-6" }
const _hoisted_5 = { class: "card border border-secondary" }
const _hoisted_6 = { class: "card-body p-1 m-1" }
const _hoisted_7 = { class: "text-center m-2" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "d-flex justify-content-center" }
const _hoisted_10 = { class: "text-center mt-3" }
const _hoisted_11 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("form", {
        class: "w-100",
        onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.beginSSOLogin && _ctx.beginSSOLogin(...args)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("img", {
                    src: _ctx.get_image_url('/virgo-dot.svg'),
                    width: "128",
                    height: "128",
                    alt: "Virgo SVS Logo"
                  }, null, 8, _hoisted_8)
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _withDirectives(_createElementVNode("input", {
                    type: "email",
                    class: "form-control w-75",
                    id: "sso_email",
                    placeholder: "Enter your E-mail",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.ssoEmail) = $event)),
                    style: {"width":"60%"}
                  }, null, 512), [
                    [_vModelText, _ctx.ssoEmail]
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("button", {
                    disabled: _ctx.ssoSubmission,
                    class: "btn btn-success w-75",
                    type: "submit"
                  }, "SSO LOGIN", 8, _hoisted_11)
                ])
              ]),
              _cache[2] || (_cache[2] = _createElementVNode("div", { class: "card-footer text-center" }, [
                _createElementVNode("div", { class: "text-muted" }, [
                  _createElementVNode("div", null, " Access to this site is limited to authorized organizations only! "),
                  _createElementVNode("div", null, " Virgo Surgical Video Solutions logs all interactions and any unauthorized attempts will be supplied to the proper authorities. ")
                ])
              ], -1))
            ])
          ])
        ])
      ], 32)
    ])
  ]))
}