import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, toDisplayString as _toDisplayString, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "w-100 col-12 p-0" }
const _hoisted_2 = { class: "card border border-secondary" }
const _hoisted_3 = { class: "card-body w-100 text-success" }
const _hoisted_4 = { enctype: "multipart/form-data" }
const _hoisted_5 = { class: "row h-100 align-items-center p-2" }
const _hoisted_6 = { class: "row p-2" }
const _hoisted_7 = { class: "col-12 btn btn-outline-info h-100" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = {
  key: 0,
  class: "row font-weight-bold p-0"
}
const _hoisted_12 = {
  key: 1,
  class: "row font-weight-bold p-0"
}
const _hoisted_13 = {
  key: 2,
  class: "row"
}
const _hoisted_14 = { class: "card-footer text-center" }
const _hoisted_15 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[10] || (_cache[10] = _createElementVNode("div", { class: "card-header" }, [
        _createElementVNode("h4", { class: "text-dark text-center" }, "DEVICE CERTIFICATE")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("form", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[6] || (_cache[6] = _createElementVNode("div", {
              class: _normalizeClass({'col-5' : true, 'text-dark' : true, 'text-danger' : true, 'h-100': true, 'p-1' : true })
            }, "PFX PASSWORD", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "password",
              class: "form-control col-7 p-1 text-right",
              name: "password",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pfx_password) = $event))
            }, null, 512), [
              [_vModelText, _ctx.pfx_password]
            ])
          ]),
          _cache[9] || (_cache[9] = _createElementVNode("div", { class: "row font-weight-bold p-0" }, [
            _createElementVNode("h4", { class: "card-header col-12 text-center text-info" }, "PFX FILE")
          ], -1)),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, [
              _createElementVNode("input", {
                type: "file",
                name: "pfx_file",
                disabled: _ctx.uploading,
                accept: ".pfx",
                class: "input-file",
                onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.pfxFileChanged && _ctx.pfxFileChanged(...args)))
              }, null, 40, _hoisted_8),
              (!_ctx.pfx_files)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(" SELECT ")
                  ], 64))
                : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.pfx_files, (file) => {
                    return (_openBlock(), _createElementBlock("span", {
                      key: file.name
                    }, _toDisplayString(file.name), 1))
                  }), 128))
            ])
          ]),
          _createElementVNode("div", {
            class: "row dropbox justify-content-center align-content-center",
            onDrop: [
              _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.fileDropped && _ctx.fileDropped(...args))),
              _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"]))
            ],
            onDragover: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
          }, [
            (!_ctx.uploading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, " Drop your PFX file here "))
              : (_openBlock(), _createElementBlock("div", _hoisted_10, " Uploading file... "))
          ], 32),
          (_ctx.results)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, _cache[7] || (_cache[7] = [
                _createElementVNode("h4", { class: "card-header col-12 text-center text-info" }, "UPLOAD RESULTS", -1)
              ])))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.results, (result) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "row",
              key: result
            }, _toDisplayString(result), 1))
          }), 128)),
          (_ctx.error_info)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, _cache[8] || (_cache[8] = [
                _createElementVNode("h4", { class: "card-header col-12 text-center text-warning" }, "ERROR", -1)
              ])))
            : _createCommentVNode("", true),
          (_ctx.error_info)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.error_info), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("a", {
            class: _normalizeClass({'col-12' : true, btn: true, 'btn-warning' : !_ctx.error, 'btn-danger' : _ctx.error, 'font-weight-bold' : true, }),
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.upload_cert()))
          }, [
            _createElementVNode("div", {
              class: _normalizeClass({'spinner-grow': _ctx.uploading})
            }, [
              (!_ctx.uploading)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(" INSTALL CERT ")
                  ], 64))
                : _createCommentVNode("", true)
            ], 2)
          ], 2)
        ])
      ])
    ])
  ]))
}