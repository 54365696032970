import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card border border-secondary card-margin" }
const _hoisted_2 = { class: "row align-self-center" }
const _hoisted_3 = { class: "card-footer" }
const _hoisted_4 = { class: "text-dark text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gauge = _resolveComponent("gauge")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "card-header" }, [
      _createElementVNode("h4", { class: "text-dark text-center" }, "BANDWIDTH")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_gauge, {
        label: _ctx.get_label(_ctx.bandwidth),
        color: _ctx.get_color(_ctx.bandwidth),
        reading: _ctx.bandwidth.percent,
        type: "radialBar"
      }, null, 8, ["label", "color", "reading"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("h5", _hoisted_4, _toDisplayString(_ctx.get_video(_ctx.bandwidth)), 1)
    ])
  ]))
}