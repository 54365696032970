import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "card border border-secondary" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "text-dark text-center" }
const _hoisted_4 = { class: "card-body w-100 text-success" }
const _hoisted_5 = { class: "row h-100 align-items-center" }
const _hoisted_6 = { class: "col-7 text-md-right" }
const _hoisted_7 = {
  class: "btn btn-success dropdown-toggle",
  "data-toggle": "dropdown",
  href: "#",
  role: "button",
  "aria-haspopup": "false",
  "aria-expanded": "false"
}
const _hoisted_8 = { class: "dropdown-menu dropdown-menu-right dropdown-menu-animated dropdown-lg menu-fix" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "row h-100 align-items-center p-1" }
const _hoisted_12 = ["onUpdate:modelValue"]
const _hoisted_13 = { class: "row h-100 align-items-center p-1" }
const _hoisted_14 = ["onUpdate:modelValue"]
const _hoisted_15 = {
  key: 1,
  class: "row h-100 align-items-center p-1"
}
const _hoisted_16 = { class: "col-5 text-dark h-100" }
const _hoisted_17 = ["onUpdate:modelValue"]
const _hoisted_18 = { class: "card-footer text-center" }
const _hoisted_19 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.ethernet?"ETH":"WIFI") + " CONFIGURATION", 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "col-5 text-dark h-100" }, "CONFIGURATION", -1)),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("a", _hoisted_7, [
            (_ctx.net_config.name)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(_toDisplayString(_ctx.net_config.name), 1)
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(" SELECT CONFIG ")
                ], 64))
          ]),
          _createElementVNode("ul", _hoisted_8, [
            (_ctx.ethernet)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.eth_configs, (config) => {
                  return (_openBlock(), _createElementBlock("li", {
                    class: "dropdown-item badge-light",
                    key: config.name,
                    onClick: ($event: any) => (_ctx.set_config(config))
                  }, _toDisplayString(config.name), 9, _hoisted_9))
                }), 128))
              : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.wifi_configs, (config) => {
                  return (_openBlock(), _createElementBlock("li", {
                    class: "dropdown-item badge-light",
                    key: config.name,
                    onClick: ($event: any) => (_ctx.set_config(config))
                  }, _toDisplayString(config.name), 9, _hoisted_10))
                }), 128))
          ])
        ])
      ]),
      (_ctx.net_config)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.net_config.fields, (field) => {
            return (_openBlock(), _createElementBlock("div", { key: field }, [
              (field.includes('PASSWORD'))
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("div", {
                        class: _normalizeClass({'col-5' : true, 'text-dark' : _ctx.fields[field] == _ctx.password[field], 'text-danger' : _ctx.fields[field] != _ctx.password[field], 'h-100': true, 'p-1' : true })
                      }, _toDisplayString(field), 3),
                      _withDirectives(_createElementVNode("input", {
                        type: "password",
                        class: "form-control col-7 p-1 text-right",
                        name: "{{field}}",
                        "onUpdate:modelValue": ($event: any) => ((_ctx.fields[field]) = $event)
                      }, null, 8, _hoisted_12), [
                        [_vModelText, _ctx.fields[field]]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("div", {
                        class: _normalizeClass({'col-5' : true, 'text-dark' : _ctx.fields[field] == _ctx.password[field], 'text-danger' : _ctx.fields[field] != _ctx.password[field], 'h-100': true, 'p-1' : true })
                      }, _toDisplayString(field), 3),
                      _withDirectives(_createElementVNode("input", {
                        type: "password",
                        class: "form-control col-7 p-1 text-right",
                        name: "{{field}}-TWO",
                        "onUpdate:modelValue": ($event: any) => ((_ctx.password[field]) = $event)
                      }, null, 8, _hoisted_14), [
                        [_vModelText, _ctx.password[field]]
                      ])
                    ])
                  ], 64))
                : (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, _toDisplayString(field), 1),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "form-control col-7 text-right",
                      name: "{{field}}",
                      "onUpdate:modelValue": ($event: any) => ((_ctx.fields[field]) = $event)
                    }, null, 8, _hoisted_17), [
                      [_vModelText, _ctx.fields[field]]
                    ])
                  ]))
            ]))
          }), 128))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_18, [
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("a", {
          class: _normalizeClass({'col-12' : true, btn: true, 'btn-warning' : !_ctx.update_error, 'btn-danger' : _ctx.update_error, 'font-weight-bold' : true, }),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.update_config()))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass({'spinner-grow': _ctx.updating})
          }, [
            (!_ctx.updating)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(" UPDATE ")
                ], 64))
              : _createCommentVNode("", true)
          ], 2)
        ], 2)
      ])
    ])
  ]))
}