import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "d-flex-row justify-content-between" }
const _hoisted_3 = {
  key: 0,
  class: "justify-content-around d-flex flex-column"
}
const _hoisted_4 = { class: "text-info m-0" }
const _hoisted_5 = { class: "text-primary m-0" }
const _hoisted_6 = {
  key: 1,
  class: "justify-content-between align-content-between d-flex flex-row flex-wrap"
}
const _hoisted_7 = { class: "p-0 d-flex justify-content-start" }
const _hoisted_8 = { class: "text-info" }
const _hoisted_9 = { class: "p-0 d-flex justify-content-end" }
const _hoisted_10 = { class: "text-primary" }
const _hoisted_11 = { class: "d-flex-row justify-content-between" }
const _hoisted_12 = { class: "p-1 d-inline-block col-6" }
const _hoisted_13 = { class: "stacked-bar text-center" }
const _hoisted_14 = { class: "text-success m-0" }
const _hoisted_15 = { class: "p-1 d-inline-block col-6" }
const _hoisted_16 = { class: "stacked-bar text-center" }
const _hoisted_17 = { class: "text-danger m-0" }
const _hoisted_18 = { class: "d-flex-row justify-content-between" }
const _hoisted_19 = { class: "d-inline-block col-11" }
const _hoisted_20 = { class: "app-search" }
const _hoisted_21 = { class: "input-group" }
const _hoisted_22 = {
  key: 2,
  class: "d-flex-row justify-content-between"
}
const _hoisted_23 = { class: "p-1 my-2" }
const _hoisted_24 = {
  key: 0,
  class: "d-flex flex-row"
}
const _hoisted_25 = {
  key: 0,
  class: "device-selection ml-2 p-2 text-center align-self-center col-auto"
}
const _hoisted_26 = { class: "form-check" }
const _hoisted_27 = ["checked"]
const _hoisted_28 = { class: "d-flex flex-row justify-content-between" }
const _hoisted_29 = {
  key: 0,
  class: "device-selection p-2 align-self-center text-center"
}
const _hoisted_30 = { class: "form-check" }
const _hoisted_31 = ["disabled", "checked", "onClick"]
const _hoisted_32 = { class: "device-status m-1" }
const _hoisted_33 = { class: "m-1 p-1" }
const _hoisted_34 = { class: "m-2 align-self-center" }
const _hoisted_35 = { class: "d-flex flex-row justify-content-between" }
const _hoisted_36 = { class: "m-1" }
const _hoisted_37 = { class: "m-2 text-truncate" }
const _hoisted_38 = { class: "m-1 list-unstyled" }
const _hoisted_39 = { class: "m-2 text-truncate" }
const _hoisted_40 = { class: "m-1 list-unstyled" }
const _hoisted_41 = { class: "device-links m-1 w-100" }
const _hoisted_42 = { class: "links p-1 d-flex justify-content-between align-items-center" }
const _hoisted_43 = ["href"]
const _hoisted_44 = { class: "box" }
const _hoisted_45 = ["src"]
const _hoisted_46 = ["href"]
const _hoisted_47 = { class: "box" }
const _hoisted_48 = ["src"]
const _hoisted_49 = ["href"]
const _hoisted_50 = { class: "box" }
const _hoisted_51 = ["src"]
const _hoisted_52 = ["href"]
const _hoisted_53 = { class: "box" }
const _hoisted_54 = ["src"]
const _hoisted_55 = {
  key: 0,
  class: "device-selection p-2 align-self-center text-center"
}
const _hoisted_56 = { class: "form-check" }
const _hoisted_57 = ["disabled", "checked", "onClick"]
const _hoisted_58 = { class: "device-status m-1 col-auto" }
const _hoisted_59 = { class: "device-age m-1 align-self-center col-1" }
const _hoisted_60 = { class: "device-name m-2 align-self-center col-2 text-truncate" }
const _hoisted_61 = { class: "device-uuid m-1 align-self-center col-1" }
const _hoisted_62 = { class: "device-ips m-2 align-self-center col-1 text-truncate" }
const _hoisted_63 = { class: "m-1 list-unstyled" }
const _hoisted_64 = { class: "device-ips m-2 align-self-center col-1 text-truncate" }
const _hoisted_65 = { class: "m-1 list-unstyled" }
const _hoisted_66 = { class: "device-links m-1 align-self-center col-auto" }
const _hoisted_67 = { class: "links p-1 d-flex justify-content-start align-items-center" }
const _hoisted_68 = ["href"]
const _hoisted_69 = { class: "box" }
const _hoisted_70 = ["src"]
const _hoisted_71 = ["href"]
const _hoisted_72 = { class: "box" }
const _hoisted_73 = ["src"]
const _hoisted_74 = ["href"]
const _hoisted_75 = { class: "box" }
const _hoisted_76 = ["src"]
const _hoisted_77 = ["href"]
const _hoisted_78 = { class: "box" }
const _hoisted_79 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DeviceProvisionForm = _resolveComponent("DeviceProvisionForm")!
  const _component_DeviceBadge = _resolveComponent("DeviceBadge")!
  const _component_scroll_area = _resolveComponent("scroll-area")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.compactDisplay)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("h5", _hoisted_4, _toDisplayString(_ctx.title), 1),
            _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.time), 1)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("h3", _hoisted_8, _toDisplayString(_ctx.title), 1)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("h3", _hoisted_10, _toDisplayString(_ctx.time), 1)
            ])
          ])),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _cache[3] || (_cache[3] = _createElementVNode("h6", { class: "text-success m-0" }, "ONLINE", -1)),
            _createElementVNode("h3", _hoisted_14, _toDisplayString(_ctx.get_online_count(_ctx.devices)), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _cache[4] || (_cache[4] = _createElementVNode("h6", { class: "text-danger m-0" }, "OFFLINE", -1)),
            _createElementVNode("h3", _hoisted_17, _toDisplayString(_ctx.get_offline_count(_ctx.devices)), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.csv_devices && _ctx.csv_devices(...args))),
          class: "d-inline-block col-1 btn btn-outline-primary btn-rounded download"
        }, _cache[5] || (_cache[5] = [
          _createElementVNode("i", { class: "mdi mdi-download" }, null, -1)
        ])),
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("div", _hoisted_21, [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "form-control",
                placeholder: "Search...",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filter) = $event))
              }, null, 512), [
                [_vModelText, _ctx.filter]
              ]),
              _cache[6] || (_cache[6] = _createElementVNode("span", { class: "mdi mdi-magnify search-icon" }, null, -1))
            ])
          ])
        ])
      ]),
      (_ctx.canProvision)
        ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
            _createElementVNode("div", _hoisted_23, [
              _createVNode(_component_DeviceProvisionForm, {
                "selected-devices": _ctx.selectedDevices.map(device => { return device.uuid })
              }, null, 8, ["selected-devices"])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    (!_ctx.compactDisplay)
      ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
          (_ctx.canProvision)
            ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                _createElementVNode("div", _hoisted_26, [
                  _createElementVNode("input", {
                    class: "form-check-input position-static",
                    type: "checkbox",
                    id: "device-select-all-none",
                    checked: _ctx.filteredOnlineDevices.length > 0 && _ctx.selectedDevices.length === _ctx.filteredOnlineDevices.length,
                    onClick: _cache[2] || (_cache[2] = () => {
                    if (_ctx.selectedDevices.length < _ctx.filteredOnlineDevices.length) {
                        _ctx.onDeviceSelectAll();
                    } else {
                        _ctx.onDeviceDeselectAll();
                    }
                    })
                  }, null, 8, _hoisted_27)
                ])
              ]))
            : _createCommentVNode("", true),
          _cache[7] || (_cache[7] = _createStaticVNode("<div class=\"device-status m-1 col-auto\" data-v-dc175aba>STATUS</div><div class=\"device-age m-1 col-1\" data-v-dc175aba>LAST ONLINE</div><div class=\"device-name m-2 col-2\" data-v-dc175aba>NAME</div><div class=\"device-uuid m-1 col-1\" data-v-dc175aba>UUID</div><div class=\"device-ips m-2 col-1\" data-v-dc175aba>IPs</div><div class=\"device-macs m-2 col-1\" data-v-dc175aba>MACs</div><div class=\"device-links m-1 col-auto\" data-v-dc175aba>LINKS</div>", 7))
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_scroll_area, { striped: true }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filtered_devices, (device) => {
          return (_openBlock(), _createElementBlock("div", {
            key: device.name,
            class: _normalizeClass({   'device-table' : true,
                            'w-100' : true,
                            'd-inline-flex' : true,
                            'flex-wrap' : true,
                            'flex-row' : !_ctx.compactDisplay,
                            'flex-column' : _ctx.compactDisplay
                        })
          }, [
            (_ctx.compactDisplay)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("div", _hoisted_28, [
                    (_ctx.canProvision)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                          _createElementVNode("div", _hoisted_30, [
                            _createElementVNode("input", {
                              disabled: device.status === 'Offline',
                              checked: _ctx.selectedDevices.includes(device),
                              class: "form-check-input position-static",
                              type: "checkbox",
                              onClick: () => { _ctx.onDeviceToggle(device); }
                            }, null, 8, _hoisted_31)
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_32, [
                      _createVNode(_component_DeviceBadge, {
                        good: device.status==='Online',
                        fail: device.status==='Offline',
                        state: device.status
                      }, null, 8, ["good", "fail", "state"])
                    ]),
                    _createElementVNode("div", _hoisted_33, _toDisplayString(device.age.substring(0,device.age.lastIndexOf('.'))), 1)
                  ]),
                  _createElementVNode("div", _hoisted_34, [
                    _createElementVNode("h4", null, _toDisplayString(device.name), 1)
                  ]),
                  _createElementVNode("div", _hoisted_35, [
                    _createElementVNode("div", _hoisted_36, [
                      _cache[8] || (_cache[8] = _createElementVNode("h6", null, "UUID", -1)),
                      _createTextVNode(" " + _toDisplayString(device.uuid.substring(0,7)), 1)
                    ]),
                    _createElementVNode("div", _hoisted_37, [
                      _cache[9] || (_cache[9] = _createElementVNode("h6", null, "IPs", -1)),
                      _createElementVNode("ul", _hoisted_38, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(device.ips, (ip) => {
                          return (_openBlock(), _createElementBlock("li", { key: ip }, _toDisplayString(ip), 1))
                        }), 128))
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_39, [
                      _cache[10] || (_cache[10] = _createElementVNode("h6", null, "MACs", -1)),
                      _createElementVNode("ul", _hoisted_40, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(device.macs, (mac) => {
                          return (_openBlock(), _createElementBlock("li", { key: mac }, _toDisplayString(mac), 1))
                        }), 128))
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_41, [
                    _createElementVNode("div", _hoisted_42, [
                      (_ctx.check_services('portal-admin'))
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 0,
                            class: "btn btn-info",
                            target: "_blank",
                            href: _ctx.get_portal_url(_ctx.fleet, device.uuid)
                          }, [
                            _createElementVNode("div", _hoisted_44, [
                              _createElementVNode("img", {
                                src: _ctx.get_image_url('/portal-196.png'),
                                alt: "Virgo Admin Portal"
                              }, null, 8, _hoisted_45)
                            ])
                          ], 8, _hoisted_43))
                        : _createCommentVNode("", true),
                      (_ctx.check_services('balena'))
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 1,
                            class: "btn btn-info",
                            target: "_blank",
                            href: device.balena_url
                          }, [
                            _createElementVNode("div", _hoisted_47, [
                              _createElementVNode("img", {
                                src: _ctx.get_image_url('/balena_logo.png'),
                                alt: "Balena.io"
                              }, null, 8, _hoisted_48)
                            ])
                          ], 8, _hoisted_46))
                        : _createCommentVNode("", true),
                      (_ctx.check_services('datadog'))
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 2,
                            class: "btn btn-info",
                            target: "_blank",
                            href: device.datadog_url
                          }, [
                            _createElementVNode("div", _hoisted_50, [
                              _createElementVNode("img", {
                                src: _ctx.get_image_url('/data_dog.jpeg'),
                                alt: "DataDog.com"
                              }, null, 8, _hoisted_51)
                            ])
                          ], 8, _hoisted_49))
                        : _createCommentVNode("", true),
                      _createElementVNode("a", {
                        class: "btn btn-info",
                        target: "_blank",
                        href: _ctx.get_device_url(_ctx.fleet,device.uuid)
                      }, [
                        _createElementVNode("div", _hoisted_53, [
                          _createElementVNode("img", {
                            src: _ctx.get_image_url('/virgo-dot.svg'),
                            alt: "Virgo"
                          }, null, 8, _hoisted_54)
                        ])
                      ], 8, _hoisted_52)
                    ])
                  ])
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  (_ctx.canProvision)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_55, [
                        _createElementVNode("div", _hoisted_56, [
                          _createElementVNode("input", {
                            disabled: device.status === 'Offline',
                            checked: _ctx.selectedDevices.includes(device),
                            class: "form-check-input position-static",
                            type: "checkbox",
                            onClick: () => { _ctx.onDeviceToggle(device); }
                          }, null, 8, _hoisted_57)
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_58, [
                    _createVNode(_component_DeviceBadge, {
                      good: device.status==='Online',
                      fail: device.status==='Offline',
                      state: device.status
                    }, null, 8, ["good", "fail", "state"])
                  ]),
                  _createElementVNode("div", _hoisted_59, _toDisplayString(device.age.substring(0,device.age.lastIndexOf('.'))), 1),
                  _createElementVNode("div", _hoisted_60, _toDisplayString(device.name), 1),
                  _createElementVNode("div", _hoisted_61, _toDisplayString(device.uuid.substring(0,7)), 1),
                  _createElementVNode("div", _hoisted_62, [
                    _createElementVNode("ul", _hoisted_63, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(device.ips, (ip) => {
                        return (_openBlock(), _createElementBlock("li", { key: ip }, _toDisplayString(ip), 1))
                      }), 128))
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_64, [
                    _createElementVNode("ul", _hoisted_65, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(device.macs, (mac) => {
                        return (_openBlock(), _createElementBlock("li", { key: mac }, _toDisplayString(mac), 1))
                      }), 128))
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_66, [
                    _createElementVNode("div", _hoisted_67, [
                      (_ctx.check_services('portal-admin'))
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 0,
                            class: "btn btn-info",
                            target: "_blank",
                            href: _ctx.get_portal_url(_ctx.fleet, device.uuid)
                          }, [
                            _createElementVNode("div", _hoisted_69, [
                              _createElementVNode("img", {
                                src: _ctx.get_image_url('/portal-196.png'),
                                alt: "Virgo Admin Portal"
                              }, null, 8, _hoisted_70)
                            ])
                          ], 8, _hoisted_68))
                        : _createCommentVNode("", true),
                      (_ctx.check_services('balena'))
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 1,
                            class: "btn btn-info",
                            target: "_blank",
                            href: device.balena_url
                          }, [
                            _createElementVNode("div", _hoisted_72, [
                              _createElementVNode("img", {
                                src: _ctx.get_image_url('/balena_logo.png'),
                                alt: "Balena.io"
                              }, null, 8, _hoisted_73)
                            ])
                          ], 8, _hoisted_71))
                        : _createCommentVNode("", true),
                      (_ctx.check_services('datadog'))
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 2,
                            class: "btn btn-info",
                            target: "_blank",
                            href: device.datadog_url
                          }, [
                            _createElementVNode("div", _hoisted_75, [
                              _createElementVNode("img", {
                                src: _ctx.get_image_url('/data_dog.jpeg'),
                                alt: "DataDog.com"
                              }, null, 8, _hoisted_76)
                            ])
                          ], 8, _hoisted_74))
                        : _createCommentVNode("", true),
                      _createElementVNode("a", {
                        class: "btn btn-info",
                        target: "_blank",
                        href: _ctx.get_device_url(_ctx.fleet, device.uuid)
                      }, [
                        _createElementVNode("div", _hoisted_78, [
                          _createElementVNode("img", {
                            src: _ctx.get_image_url('/virgo-dot.svg'),
                            alt: "Virgo"
                          }, null, 8, _hoisted_79)
                        ])
                      ], 8, _hoisted_77)
                    ])
                  ])
                ], 64))
          ], 2))
        }), 128))
      ]),
      _: 1
    })
  ]))
}