import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card border border-secondary card-margin" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "text-success" }
const _hoisted_4 = {
  key: 0,
  class: "card-body"
}
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-8" }
const _hoisted_7 = { class: "text-info" }
const _hoisted_8 = { class: "col-4 text-right" }
const _hoisted_9 = { class: "text-info" }
const _hoisted_10 = {
  key: 0,
  class: "card-body"
}
const _hoisted_11 = {
  key: 1,
  class: "card-body"
}
const _hoisted_12 = { class: "card footer-links" }
const _hoisted_13 = { class: "row m-1" }
const _hoisted_14 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.title), 1)
    ]),
    (_ctx.summaryReady)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.deviceKeys, (key) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "w-auto",
              key: key
            }, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("h4", _hoisted_7, _toDisplayString(key), 1)
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("h4", _hoisted_9, _toDisplayString((_ctx.fleet_devices[key])), 1)
                ])
              ])
            ]))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.loadingSummary)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, _cache[0] || (_cache[0] = [
                _createElementVNode("div", { class: "row" }, [
                  _createElementVNode("div", { class: "col-5" }),
                  _createElementVNode("div", {
                    class: "spinner-grow avatar-lg text-info col-auto",
                    role: "status"
                  }),
                  _createElementVNode("div", { class: "col-5" })
                ], -1)
              ])))
            : _createCommentVNode("", true),
          (_ctx.error)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("h2", null, _toDisplayString(_ctx.errorMessage) + "!", 1)
              ]))
            : _createCommentVNode("", true)
        ], 64)),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("a", {
          href: _ctx.buttonLink,
          class: "btn btn-outline-primary w-100"
        }, _toDisplayString(_ctx.buttonLabel), 9, _hoisted_14)
      ])
    ])
  ]))
}