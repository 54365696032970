import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "card border border-secondary m-1" }
const _hoisted_2 = { class: "card-body col" }
const _hoisted_3 = {
  key: 0,
  class: "row"
}
const _hoisted_4 = { class: "col-1" }
const _hoisted_5 = { class: "col-3" }
const _hoisted_6 = {
  key: 0,
  class: "text-warning col-8"
}
const _hoisted_7 = { style: {"overflow":"hidden","white-space":"nowrap","width":"100%"} }
const _hoisted_8 = {
  key: 1,
  class: "col-8"
}
const _hoisted_9 = {
  class: "text-info",
  style: {"overflow":"hidden","white-space":"nowrap","width":"100%"}
}
const _hoisted_10 = { class: "card-footer text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "card-header" }, [
      _createElementVNode("h4", { class: "text-success text-center" }, "DEVICE PROXY")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.ready)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[0] || (_cache[0] = [
            _createElementVNode("div", {
              class: "spinner-grow avatar-lg text-info col-4 loading-spinner",
              role: "status"
            }, null, -1)
          ])))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.proxies, (proxy) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "row",
              key: proxy.region
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("h4", null, _toDisplayString(proxy.region), 1)
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("h4", {
                  style: _normalizeStyle(_ctx.get_style(proxy))
                }, _toDisplayString(_ctx.get_status(proxy)), 5)
              ]),
              (proxy.error)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("h4", _hoisted_7, _toDisplayString(proxy.error.err), 1)
                  ]))
                : _createCommentVNode("", true),
              (proxy.result)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createElementVNode("h4", _hoisted_9, _toDisplayString(proxy.response.join(" ")), 1)
                  ]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("h4", {
        style: _normalizeStyle(_ctx.get__service_style)
      }, _toDisplayString(_ctx.get_service_status), 5)
    ])
  ]))
}